import { writable } from "svelte/store";
import { browser } from "$app/environment";

export const theme = writable(
  (browser &&
    JSON.parse(window.localStorage.getItem('theme'))
  ) ||
  (browser &&
    window.localStorage.setItem('theme', JSON.stringify({theme: 'light', color: true})) &&
    {theme: 'light', color: true}
  )
);
